<template>
    <div class="flex-1">
        <!-- 상세검색 -->
        <div class="scomm-detail-search">
            <div class="scomm-detail-search__cate">
                <ul>
                    <li>{{ this.srchDateTypeText }}</li>
                    <li>{{ this.srchPostText }}</li>
                    <li>{{ this.srchTypeText }}</li>
                </ul>
                <div v-if="this.postSearchParam.srchText" class="display-inline-block"><span class="keyword">{{ this.postSearchParam.srchText }}</span> 키워드로 검색 결과 : <span class="keyword">{{ this.totalCount }}</span> 건</div>
                <div v-else class="display-inline-block">검색 결과 : <span class="keyword">{{ this.totalCount }}</span> 건</div>
            </div>
            <div class="scomm-detail-search__form">
                <fieldset>
                    <legend class="sr-only">상세 검색</legend>

                    <div class="field">
                        <!-- 전체기간 -->
                        <div class="control">
                            <DropDown ref="drdSearchDate" id="drdSearchDate" init="A" placeholder="전체기간">
                                <div class="dropdown-item" data-value="A">
                                    <button type="button" @click="clickDropdownSearchDate($event)" class="text-link">
                                        <span class="dropdown-text">전체기간</span>
                                    </button>
                                </div>
                                <div class="dropdown-item" data-value="D">
                                    <button type="button" @click="clickDropdownSearchDate($event)" class="text-link">
                                        <span class="dropdown-text">1일</span>
                                    </button>
                                </div>
                                <div class="dropdown-item" data-value="W">
                                    <button type="button" @click="clickDropdownSearchDate($event)" class="text-link">
                                        <span class="dropdown-text">1주</span>
                                    </button>
                                </div>
                                <div class="dropdown-item" data-value="M">
                                    <button type="button" @click="clickDropdownSearchDate($event)" class="text-link">
                                        <span class="dropdown-text">1개월</span>
                                    </button>
                                </div>
                                <div class="dropdown-item" data-value="H">
                                    <button type="button" @click="clickDropdownSearchDate($event)" class="text-link">
                                        <span class="dropdown-text">6개월</span>
                                    </button>
                                </div>
                                <div class="dropdown-item" data-value="Y">
                                    <button type="button" @click="clickDropdownSearchDate($event)" class="text-link">
                                        <span class="dropdown-text">1년</span>
                                    </button>
                                </div>
                                <div class="dropdown-divider"></div>
                                <div class="dropdown-item dropdown-item-date" data-value="I">
                                    <span class="dropdown-text">기간 입력</span>
                                    <div class="input-date-wrap">
                                        <input type="date" class="input dropdown-item-startdate" />
                                        <input type="date" class="input dropdown-item-enddate" />
                                        <button type="button" class="button-default is-small" @click="clickDropdownSearchDate($event)">설정</button>
                                    </div>
                                </div>
                            </DropDown>
                        </div>
                        <!-- //전체기간 -->

                        <!-- 전체글 -->
                        <div class="control">
                            <DropDown ref="drdSearchPostType" id="drdSearchPostType" :init="initSearchPostType" placeholder="전체 글">
                                <div class="dropdown-item" data-value="A">
                                    <button type="button" @click="clickDropdownSearchPostType($event)" class="text-link">
                                        <span class="dropdown-text">전체 글</span>
                                    </button>
                                </div>
                                <div class="dropdown-item" data-value="L">
                                    <button type="button" @click="clickDropdownSearchPostType($event)" class="text-link">
                                        <span class="dropdown-text">내가 좋아요 한 글</span>
                                    </button>
                                </div>
                                <div class="dropdown-item" data-value="W">
                                    <button type="button" @click="clickDropdownSearchPostType($event)" class="text-link">
                                        <span class="dropdown-text">내가 쓴 글</span>
                                    </button>
                                </div>
                                <div class="dropdown-item" data-value="R">
                                    <button type="button" @click="clickDropdownSearchPostType($event)" class="text-link">
                                        <span class="dropdown-text">내가 쓴 댓글</span>
                                    </button>
                                </div>
                            </DropDown>
                        </div>
                        <!-- //전체글 -->

                        <!-- 게시글 + 댓글 -->
                        <div class="control">
                            <DropDown ref="drdSearchPostRply" id="drdSearchPostRply" init="A" placeholder="게시글 + 댓글">
                                <div class="dropdown-item" data-value="A">
                                    <button type="button" @click="clickDropdownSearchPostRply($event)" class="text-link">
                                        <span class="dropdown-text">게시글 + 댓글</span>
                                    </button>
                                </div>
                                <!-- <div class="dropdown-item" data-value="T">
                                    <button type="button" @click="clickDropdownSearchPostRply($event)" class="text-link">
                                        <span class="dropdown-text">제목만</span>
                                    </button>
                                </div> -->
                                <div class="dropdown-item" data-value="W">
                                    <button type="button" @click="clickDropdownSearchPostRply($event)" class="text-link">
                                        <span class="dropdown-text">글작성자</span>
                                    </button>
                                </div>
                                <div class="dropdown-item" data-value="R">
                                    <button type="button" @click="clickDropdownSearchPostRply($event)" class="text-link">
                                        <span class="dropdown-text">댓글 내용</span>
                                    </button>
                                </div>
                                <div class="dropdown-item" data-value="P">
                                    <button type="button" @click="clickDropdownSearchPostRply($event)" class="text-link">
                                        <span class="dropdown-text">댓글 작성자</span>
                                    </button>
                                </div>
                            </DropDown>
                        </div>
                        <!-- //게시글 + 댓글 -->

                        <div class="control control-input" @keyup.enter="getSCommunityPostList(1)">
                            <input v-model="postSearchParam.srchText" type="text" class="input" />
                        </div>
                        <div class="control-submit">
                            <button type="submit" class="button-default button-submit is-primary" @click="getSCommunityPostList(1)">검색</button>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <!-- //상세검색 -->
        <!-- 게시글 -->
        <section class="scomm-detail-list">
            <h3 class="sr-only">게시글 목록</h3>
            <ul class="scomm-detail-articles">                
                <li v-for="(item, idx) in this.posts" :key="idx" :class="{ 'is-pinned': item.topYn == 'Y' }">
                    <scommunity-post :post-item="item" :cmnt-user-status="scommunityUserAtbt.rgtrYn" :cmntAdminYn="cmntAdminYn" @getSCommunityPostList="getSCommunityPostList"> </scommunity-post>
                </li>
                <div v-if="this.postEmpty" class="no-results-found">
                    <p class="text">등록된 게시글이 없습니다.</p>
                </div>
            </ul>
        </section>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DropDown from '@/components/common/DropDown';
import ScommunityPost from '@/components/scommunity/SCommunityPost.vue';
import { 
	ACT_GET_SCOMMUNITY_POST_LIST,
} from '../../store/_act_consts';
import { MUT_SHOW_ALERT, MUT_SHOW_CONFIRM } from '../../store/_mut_consts';
import { getCheckItems, getItems, lengthCheck, setPaging } from '../../assets/js/utils';
export default {
	name: 'ScommunityPostSearch',
	components: {
        ScommunityPost,
		DropDown,
	},
	data: () => ({
		hasMore: false,
		totalCount: 0,
		pageSize: 10,
		pageListSize: 5,
		pageNo: 1,
        srchDateTypeText: '',
        srchPostText: '',
        srchTypeText: '',
        postEmpty: false,
        postSearchParam: {
			dateType: 'A',
			startYmd: '',
			endYmd: '',
			srchPost: 'A',
			srchType: 'A',
			srchText: '',
			userId: 0,
			pageNo: 1,
			pageSize: 10,
		},
        posts: [],
	}),
	props: {
        scommunityNo: String,
        scommunityUserAtbt: {
			type: Object,
			default: {},
		},
        initSearchPostType: String,
        initSearchText: String,
        cmntAdminYn: Boolean,
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
	},
	watch: {
        initSearchPostType: function(searchPostType){
			this.postSearchParam.srchPost = searchPostType;
            this.$refs.drdSearchPostType.setDropdownItemByValue(searchPostType);
            this.getSCommunityPostList(1); 
		},
    },
	created() {
        this.postSearchParam.srchPost = this.initSearchPostType;
        this.postSearchParam.srchText = this.initSearchText;
        this.getSCommunityPostList(1);        
    },
    mounted() {
		window.addEventListener('scroll', this.handleListScroll);
        this.srchDateTypeText = this.$refs.drdSearchDate.getDropdownSelectedTitle();
        this.srchPostText = this.$refs.drdSearchPostType.getDropdownSelectedTitle();
        this.srchTypeText = this.$refs.drdSearchPostRply.getDropdownSelectedTitle();
	},
	unmounted(){
		window.removeEventListener('scroll', this.handleListScroll);
	},
	methods: {
        getSCommunityPostList(no){
			// postSearchParam 설정
			this.postSearchParam.userId = this.session.userId;
			this.postSearchParam.pageNo = no;
			this.postSearchParam.pageSize = this.pageSize;

			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_POST_LIST}`, {
				cmntId: this.scommunityNo,
				params: this.postSearchParam,
			}).then(res => {
				if(no === 1){
					if(lengthCheck(res)){
						this.posts = getItems(res);
                        this.postEmpty = false;
					} else {
                        this.posts = null;
						this.postEmpty = true;
					}					
				} else {
					const addPosts = getCheckItems(res);
					addPosts.forEach(element => {
						this.posts.push(element);
					});					
				}				
				setPaging(this, res);
			});
		},
		clickDropdownSearchDate(event) {
			this.$refs.drdSearchDate.selectDropdownItem(event);
            this.srchDateTypeText = this.$refs.drdSearchDate.getDropdownSelectedTitle();
            this.postSearchParam.dateType = this.$refs.drdSearchDate.getDropdownSelectedValue();
            this.postSearchParam.startYmd = this.$refs.drdSearchDate.getDropdownSelectedStartDate();
            this.postSearchParam.endYmd = this.$refs.drdSearchDate.getDropdownSelectedEndDate();
		},
		clickDropdownSearchPostType(event) {
			this.$refs.drdSearchPostType.selectDropdownItem(event);
            this.srchPostText = this.$refs.drdSearchPostType.getDropdownSelectedTitle();
            this.postSearchParam.srchPost = this.$refs.drdSearchPostType.getDropdownSelectedValue();
		},
		clickDropdownSearchPostRply(event) {
			this.$refs.drdSearchPostRply.selectDropdownItem(event);
            this.srchTypeText = this.$refs.drdSearchPostRply.getDropdownSelectedTitle();
            this.postSearchParam.srchType = this.$refs.drdSearchPostRply.getDropdownSelectedValue();
		},
        // 무한 스크롤 정의
		handleListScroll(e) {
			const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement;
			const isAtTheBottom = scrollHeight === scrollTop + clientHeight;
			// 일정 한도 밑으로 내려오면 함수 실행 && 추가 페이지가 있을때.
			if (isAtTheBottom && this.hasMore) this.handleLoadMore();
		},
		// 내려오면 api 호출하여 아래에 더 추가, total값 최대이면 호출 안함
		handleLoadMore() {
			this.pageNo += 1;
			this.getSCommunityPostList(this.pageNo);
		},
	},
};
</script>