<template>
	<!-- type: 댓글 많은 유형 -->
	<article>
		<!-- 게시글 Article -->
		<div class="scomm-detail-article">
			<div class="scomm-detail-article__header">
				<!-- 사용자 정보 -->
				<div class="scomm-detail-user">
					<div class="scomm-detail-user__photo">
						<img v-if="postItem.profileImg" :src="postItem.profileImg" alt="사진" />
						<img v-else src="" alt="" />
					</div>
					<div class="scomm-detail-user__text">
						<span class="scomm-detail-user__name">{{ postItem.rgtrNm }}</span>
						<span class="scomm-detail-user__date">{{ regDtTimeFormat(postItem.regDt) }}</span>
					</div>
				</div>
				<!-- //사용자 정보 -->
				<button type="button" class="button-open-util" @click="closeAndOpenLayer('sub_post_menu', 'divSubPostMenu_' + postItem.cmntPostId)" >
					<i class="icon-detail-more"></i>
				</button>
				<div v-if="postUpdateDelete" v-show="false" :id="'divSubPostMenu_' + postItem.cmntPostId" class="scomm-detail-article__util sub_post_menu" @mouseleave="closeLayer('sub_post_menu');">
					<button v-if="this.cmntAdminYn && postItem.topYn == 'N'" type="button" class="button" @click="updateSCommunityPostTop();">고정</button>
					<button v-if="this.cmntAdminYn && postItem.topYn == 'Y'" type="button" class="button" @click="updateSCommunityPostTop();">고정해제</button>
					<button type="button" class="button" @click="closeAndOpenLayer('post-edit', 'divPostEdit_' + postItem.cmntPostId); closeLayer('sub_post_menu');">수정</button>
					<button type="button" class="button" @click="deletePost()">삭제</button>
				</div>
				<!-- 게시글 수정 레이어 -->
				<div v-if="postUpdateDelete" v-show="false" :id="'divPostEdit_' + postItem.cmntPostId" class="layer-scomm-edit post-edit">
					<div class="layer-scomm-edit__header">
						<button type="button" @click="closeLayer('post-edit')"><i class="icon-popup-close"></i><span class="sr-only">팝업 창닫기</span></button>
					</div>
					<div class="scomm-detail-write scomm-detail-write__main">
						<fieldset>
							<legend class="sr-only">게시글 수정</legend>
							<textarea v-model="postCn" id="txtPostCn" v-on:input="postCntyping" cols="30" rows="10" class="textarea" data-type="updPost"></textarea>
							<div class="scomm-detail-write__footer">
								<div class="flex-l">
									<span class="scomm-detail-write__upload" v-show="uploadPreviewImage">
										<em class="thumb">
											<img :src="uploadPreviewImage" />
										</em>
										<button type="button" class="button-delete" @click="cancelUpload">
											<span class="sr-only">이미지 삭제</span>
										</button>
									</span>
									<span class="button-upload-photo" v-show="!uploadPreviewImage">
										<label>
											<input ref="fileInput" type="file" accept="image/*" @change="onFileSelected()" />
											<i class="icon-detail-photo"></i>
											<span class="sr-only">이미지등록</span>
										</label>
									</span>
									<span class="scomm-detail-write__footer-count">{{ this.postCnLength }}/1000</span>
								</div>
								<div class="flex-r">
									<button type="submit" class="button-default" @click="updateSCommunityPost">수정</button>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
				<!-- //게시글 수정 레이어 -->
			</div>
			<div v-html="postCnHtml" class="scomm-detail-article__content">
			</div>
			<div class="scomm-detail-article__image" v-if="previewImage">
				<LightBox :thumbnail="previewImage" :largeImage="previewImage" captions="" class="lightBox" />
			</div>
			<div class="scomm-detail-article__footer">
				<button type="button" class="button" @click="insertSCommunityPostLik">
					<i v-bind:class="[postItem.cmntLikYn === 'Y' ? 'icon-detail-like' : 'icon-detail-like-o']"></i>
					<span class="text">좋아요</span>
					<span class="nums">{{ postItem.cmntLikCount }}</span>
				</button>
				<button v-if="this.postItem.rplyCount > 0" type="button" class="button" @click="closeAndOpenLayer('ul-post-rply', 'ulPostRply_' + postItem.cmntPostId);">
					<i class="icon-detail-comment"></i>
					<span class="text">댓글</span>
					<span class="nums">{{ postItem.rplyCount }}</span>
				</button>
				<button v-else-if="this.postItem.rplyCount == 0 && rplyInsert" type="button" class="button" @click="closeAndOpenLayer('ul-post-rply', 'ulPostRply_' + postItem.cmntPostId);">
					<span class="text">댓글쓰기</span>
				</button>
			</div>
		</div>
		<!-- //게시글 Article -->
		<!--  댓글 -->
		<ul v-if="rplyOpen" v-show="false" :id="'ulPostRply_' + postItem.cmntPostId" class="ul-post-rply">
			<li>
				<template v-for="(item, idx) in postRplyList">
					<!-- 댓글 Article -->
					<template v-if="item.cmntPostRplyTyp === 'R'" >
						<div class="scomm-detail-article">
							<div class="scomm-detail-article__header">
								<!-- 사용자 정보 -->
								<div class="scomm-detail-user">
									<div class="scomm-detail-user__photo">
										<img v-if="item.profileImg" :src="item.profileImg" alt="사진" />
										<!-- <img v-else src="../../assets/img/community/reply_unknown_icon.svg" alt="사진" /> -->
										<img v-else src="" alt="" />
									</div>
									<div class="scomm-detail-user__text">
										<span class="scomm-detail-user__name">
											{{ item.rgtrNm }}
											<em v-if="session.userId == item.rgtrId" class="scomm-detail-user__tag">작성자</em>
										</span>
									</div>
								</div>
								<!-- //사용자 정보 -->
								<button type="button" class="button-open-util" @click="closeAndOpenLayer('sub_post_rply_menu', 'divSubPostRplyMenu_' + item.cmntPostRplyId)">
									<i class="icon-detail-more"></i>
								</button>
								<div v-if="(session.userId == item.rgtrId && item.delYn == 'N') || cmntAdminYn" v-show="false" :id="'divSubPostRplyMenu_' + item.cmntPostRplyId" class="scomm-detail-article__util sub_post_rply_menu" @mouseleave="closeLayer('sub_post_rply_menu');">
									<button type="button" class="button" @click="
									rplyDataSet(item); 
									closeAndOpenLayer('rply-edit', 'divPostRplyEdit_' + item.cmntPostRplyId); 
									closeLayer('sub_post_rply_menu');">수정</button>
									<button type="button" class="button" @click="deletePostRply(item.cmntPostRplyId)">삭제</button>
								</div>
								<!-- 글 수정 레이어 -->
								<div v-if="(session.userId == item.rgtrId && item.delYn == 'N') || cmntAdminYn" v-show="false" :id="'divPostRplyEdit_' + item.cmntPostRplyId" class="layer-scomm-edit rply-edit">
									<div class="layer-scomm-edit__header">
										<button type="button" @click="closeLayer('rply-edit')"><i class="icon-popup-close"></i><span class="sr-only">팝업 창닫기</span></button>
									</div>
									<div class="scomm-detail-write">
										<fieldset>
											<legend class="sr-only">수정</legend>
											<textarea v-model="postRplyCn" name="" id="" v-on:input="postCntyping" cols="30" rows="10" class="textarea" data-type="updRply"></textarea>
											<div class="scomm-detail-write__footer">
												<div class="flex-l">
													<span class="scomm-detail-write__footer-count">{{ postRplyLength }}/150</span>
												</div>
												<div class="flex-r">
													<button type="submit" class="button-default" @click="updateSCommunityPostRply(item.cmntPostRplyId, 'divPostRplyCn_' + item.cmntPostRplyId)">수정</button>
												</div>
											</div>
										</fieldset>
									</div>
								</div>
								<!-- //글 수정 레이어 -->
							</div>
							<div v-html="cnConvertHtml(item.cmntPostRplyCn)" :id="'divPostRplyCn_' + item.cmntPostRplyId" class="scomm-detail-article__content"></div>
							<div v-if="rplyInsert" class="scomm-detail-article__footer">
								<span class="date"> {{ regDtTimeFormat(item.regDt) }} </span>
								<button type="button" class="button" @click="rplyDataClear(); closeAndOpenLayer('post-rrply-input', 'liPostRRply_' + item.cmntPostRplyId)">
									<span class="text is-strong">답글쓰기</span>
								</button>
							</div>
						</div>
						<ul v-if="rplyInsert">
							<li v-show="false" :id="'liPostRRply_' + item.cmntPostRplyId" class="post-rrply-input">
								<!-- 답글 등록 -->
								<div class="scomm-detail-write">
									<fieldset>
										<legend class="sr-only">답글 등록</legend>
										<div class="scomm-detail-write__header"><strong>{{ session.userNm }}</strong></div>
										<textarea v-model="postRplyCn" name="" id="" v-on:input="postCntyping" cols="30" rows="10" class="textarea" data-type="updRply"></textarea>
										<div class="scomm-detail-write__footer">
											<div class="flex-l">
												<span class="scomm-detail-write__footer-count">{{ postRplyLength }}/150</span>
											</div>
											<div class="flex-r">
												<button type="submit" class="button-default" @click="insertSCommunityPostRRply(item.cmntPostRplyId)">등록</button>
											</div>
										</div>
									</fieldset>
								</div>
								<!-- //답글 등록 -->
							</li>
						</ul>
					</template>
					<!-- //댓글 Article -->
					<!--  답글 -->
					<ul v-else-if="item.cmntPostRplyTyp === 'RR'">
						<li>
							<!-- 답글 Article -->
							<div class="scomm-detail-article">
								<div class="scomm-detail-article__header">
									<!-- 사용자 정보 -->
									<div class="scomm-detail-user">
										<div class="scomm-detail-user__photo">
											<img v-if="item.profileImg" :src="item.profileImg" alt="사진" />
											<!-- <img v-else src="../../assets/img/community/reply_unknown_icon.svg" alt="사진" /> -->
											<img v-else src="" alt="" />
										</div>
										<div class="scomm-detail-user__text">
											<span class="scomm-detail-user__name">
												{{ item.rgtrNm }}
												<em v-if="session.userId == item.rgtrId" class="scomm-detail-user__tag">작성자</em>
											</span>
										</div>
									</div>
									<!-- //사용자 정보 -->
									<button type="button" class="button-open-util" @click="closeAndOpenLayer('sub_post_rply_menu', 'divSubPostRplyMenu_' + item.cmntPostRplyId)">
										<i class="icon-detail-more"></i>
									</button>
									<div v-if="(session.userId == item.rgtrId && item.delYn == 'N') || cmntAdminYn" v-show="false" :id="'divSubPostRplyMenu_' + item.cmntPostRplyId" class="scomm-detail-article__util sub_post_rply_menu">
										<button type="button" class="button" @click="
										rplyDataSet(item); 
										closeAndOpenLayer('rply-edit', 'divPostRplyEdit_' + item.cmntPostRplyId); 
										closeLayer('sub_post_rply_menu');">수정</button>
										<button type="button" class="button" @click="deletePostRply(item.cmntPostRplyId)">삭제</button>
									</div>
									<!-- 글 수정 레이어 -->
									<div v-if="(session.userId == item.rgtrId && item.delYn == 'N') || cmntAdminYn" v-show="false" :id="'divPostRplyEdit_' + item.cmntPostRplyId" class="layer-scomm-edit rply-edit">
										<div class="layer-scomm-edit__header">
											<button type="button" @click="closeLayer('rply-edit')"><i class="icon-popup-close"></i><span class="sr-only">팝업 창닫기</span></button>
										</div>
										<div class="scomm-detail-write">
											<fieldset>
												<legend class="sr-only">수정</legend>
												<textarea v-model="postRplyCn" name="" id="" v-on:input="postCntyping" cols="30" rows="10" class="textarea" data-type="updRply"></textarea>
												<div class="scomm-detail-write__footer">
													<div class="flex-l">
														<span class="scomm-detail-write__footer-count">{{ postRplyLength }}/150</span>
													</div>
													<div class="flex-r">
														<button type="submit" class="button-default" @click="updateSCommunityPostRply(item.cmntPostRplyId, 'divPostRplyCn_' + item.cmntPostRplyId)">수정</button>
													</div>
												</div>
											</fieldset>
										</div>
									</div>
									<!-- //글 수정 레이어 -->
								</div>
								<div v-html="cnConvertHtml(item.cmntPostRplyCn)" :id="'divPostRplyCn_' + item.cmntPostRplyId" class="scomm-detail-article__content"></div>
								<div class="scomm-detail-article__footer">
									<span class="date"> {{ regDtTimeFormat(item.regDt) }} </span>
								</div>
							</div>
							<!-- //답글 Article -->
						</li>
					</ul>
					<!--  //답글 -->
				</template>
			</li>
			<li>
				<!-- 댓글 등록 -->
				<div v-if="rplyInsert" class="scomm-detail-write">
					<fieldset>
						<legend class="sr-only">댓글 등록</legend>
						<div class="scomm-detail-write__header"><strong>{{ session.userNm }}</strong></div>
						<textarea v-model="postRplyCnIns" name="" id="" v-on:input="postCntyping" cols="30" rows="10" class="textarea" data-type="insRply"></textarea>
						<div class="scomm-detail-write__footer">
							<div class="flex-l">
								<span class="scomm-detail-write__footer-count">{{ postRplyInsLength }}/150</span>
							</div>
							<div class="flex-r">
								<button type="submit" class="button-default" @click="insertSCommunityPostRply()">등록</button>
							</div>
						</div>
					</fieldset>
				</div>
				<!-- //댓글 등록 -->
			</li>
		</ul>
		<!--  //댓글 -->
	</article>
</template>

<script>
import { mapGetters } from 'vuex';
import LightBox from '@/components/common/LightBox';
import { 
	ACT_GET_SCOMMUNITY_POST_RPLY_LIST,
	ACT_INSERT_SCOMMUNITY_POST_LIK,
	ACT_UPDATE_SCOMMUNITY_POST,
	ACT_UPDATE_SCOMMUNITY_POST_TOP,
	ACT_UPDATE_SCOMMUNITY_POST_RPLY,
	ACT_INSERT_SCOMMUNITY_POST_RPLY,
	ACT_INSERT_SCOMMUNITY_POST_RRPLY,
	ACT_UPLOAD_SCOMMUNITY_POST_IMG,
	ACT_DELETE_SCOMMUNITY_POST,
	ACT_DELETE_SCOMMUNITY_POST_RPLY,
} from '../../store/_act_consts';
import { MUT_SHOW_ALERT, MUT_SHOW_CONFIRM } from '../../store/_mut_consts';
import {timestampToDateFormat, getCheckItems, getResult} from '../../assets/js/utils';
export default {
	name: 'ScommunityPost',
	components: {
		LightBox,
	},
	data: () => ({
		postCn: '',
		postCnLength: 0,
		postRplyCn: '',
		postRplyLength: 0,
		postRplyCnIns: '',
		postRplyInsLength: 0,
		postRplyList: [],
		previewImage: '',
		uploadPreviewImage: '',
	}),
	props: {
		postItem: {
			type: Object,
			default: {},
		},
		cmntUserStatus: String,
		// cmntUserType: String,
		cmntAdminYn: Boolean,
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('scommunity', ['prhbWrds']),
		postCnHtml() {
			return this.postItem.cmntPostCn.replaceAll(/(\n|\r\n)/g,'<br>');
		},
		rplyOpen(){	// 댓글 열람 가능 : 활동 정지, 가입 사용자, 댓글 열람 불가 : 미가입, 강제탈퇴
			if(this.cmntUserStatus === 'Y' || this.cmntUserStatus === 'S'){
				return true;
			} else {
				return false;
			}
		},
		postUpdateDelete(){ // 포스트 수정 및 삭제 가능 : 작성자, 관리자
			if((this.session.userId == this.postItem.rgtrId && this.postItem.delYn == 'N') || this.cmntAdminYn){
				return true;
			} else {
				return false;
			}
		},
		rplyInsert(){	// 댓글 작성 가능 : 가입 사용자, 댓글 작성 불가 : 미가입, 활동정지, 관리자
			if(!this.cmntAdminYn && this.cmntUserStatus === 'Y'){
				return true;
			} else {
				return false;
			}
		},
	},
	watch: {
		postItem: function(newPost){
			this.postCn = newPost.cmntPostCn;
			this.postCnLength = this.getByteLength(newPost.cmntPostCn);
			this.postRplyCn = '';
			this.postRplyLength = 0,
			this.postRplyCnIns = '';
			this.postRplyInsLength = 0,
			this.previewImage = newPost.atchFilePtUrl;
			this.uploadPreviewImage = newPost.atchFilePtUrl;
			this.postRplyList = [];
			if(newPost.rplyCount > 0){
				this.getSCommunityPostRplyList();
			}
		},
	},
	created() {
		this.postCn = this.postItem.cmntPostCn;
		this.postCnLength = this.getByteLength(this.postCn);
		this.previewImage = this.postItem.atchFilePtUrl;
		this.uploadPreviewImage = this.postItem.atchFilePtUrl;
		if(this.postItem.rplyCount > 0){
			this.getSCommunityPostRplyList();
		}
	},
	methods: {
		onFileSelected() {
			let input = this.$refs.fileInput;
			let file = input.files;
			if (file && file[0]) {
				let reader = new FileReader();
				reader.onload = (e) => {
					this.uploadPreviewImage = e.target.result;
				};
				reader.readAsDataURL(file[0]);
				this.$emit('input', file[0]);
			}
		},
		cancelUpload() {
			this.uploadPreviewImage = null;
			let input = this.$refs.fileInput;
			input.value = null;
		},
		rplyDataSet(item){
			this.postRplyCn = item.cmntPostRplyCn;
			this.postRplyLength = this.getByteLength(item.cmntPostRplyCn);
		},
		rplyDataClear(){
			this.postRplyCn = '';
			this.postRplyLength = 0;
		},
		cnConvertHtml(str) {
			return str.replaceAll(/(\n|\r\n)/g,'<br>');
		},
		closeLayer(claName) {
			const editLayers = document.querySelectorAll('.' + claName);
			editLayers.forEach(element => {
				element.style.display = 'none';
			});
		},
		closeAndOpenLayer(claName, id) {
			const closeLayers = document.querySelectorAll('.' + claName);
			closeLayers.forEach(element => {
				if(element.id == id){
					if(element.style.display == 'none'){
						element.style.display = 'block';
					} else {
						element.style.display = 'none';
					}					
				} else {
					element.style.display = 'none';
				}				
			});
		},
		regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy.MM.dd hh:mm');
		},
		getSCommunityPostRplyList(){
			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_POST_RPLY_LIST}`, {
				cmntId: this.postItem.cmntId,
				cmntPostId: this.postItem.cmntPostId,
			}).then(res => {
				this.postRplyList = getCheckItems(res);
			});		
		},
		insertSCommunityPostLik(){
			if(this.postCn.length > 0) {
				this.$store.dispatch(`scommunity/${ACT_INSERT_SCOMMUNITY_POST_LIK}`, {
					cmntId: this.postItem.cmntId,
					cmntPostId: this.postItem.cmntPostId,
					userId: this.session.userId,
				}).then(res => {
					if(getResult(res).number === 200){
						if(this.postItem.cmntLikYn === 'Y'){
							this.postItem.cmntLikCount -= 1;
							this.postItem.cmntLikYn = 'N';
						} else {
							this.postItem.cmntLikCount += 1;
							this.postItem.cmntLikYn = 'Y';
						}
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
					}							
				});
			}
		},
		postCntyping : function(e){ 
			const type = e.target.getAttribute('data-type');
			const maxCnt = type === 'updPost' ? 1000 : 150;
            this.max_length(e, type, maxCnt);
        },
        max_length : function(e, type, len){
			//console.log(e.data);
            const val =  e.target.value;

			let byte = 0;
			let txtLength = 0;
			for (txtLength = 0; txtLength < val.length; txtLength++) {
				const code = val.charCodeAt(txtLength);
				let charByte = 0;
				if(code > 127){
					charByte = 2;
					byte += charByte;
				} else if(code > 64 && code < 91){
					charByte = 2;
					byte += charByte;
				} else {
					charByte = 1;
					byte += charByte;
				}

				if(byte > len){
					//txtLength = txtLength - 1;
					byte -= charByte;
					break;
				}
			}

			const cn = val.substring(0, txtLength);		

			if(type === 'updPost'){
				this.postCn = cn;
				this.postCnLength = byte;
			} else if(type === 'insRply'){
				this.postRplyCnIns = cn;
				this.postRplyInsLength = byte;
			} else {
				this.postRplyCn = cn;
				this.postRplyLength = byte;
			}	
        },
		getByteLength : function(str){
			let byte = 0;
			//const code = str.charCodeAt(0);
			for (var idx = 0; idx < str.length; idx++) {
				const code = str.charCodeAt(idx);
				if(code > 127){
					byte += 2;
				} else if(code > 64 && code < 91){
					byte += 2;
				} else {
					byte += 1;
				}
			}
			return byte;
		},
		async updateSCommunityPost(){
			if(this.postCn.length > 0) {
				if(!this.validatePrhbWrds(this.postCn)){
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '작성내용에 금칙어가 포함되어 있습니다.'});
					return;
				}

				let imgPath = '';
				console.log(this.$refs.fileInput.value);
				if (this.$refs.fileInput && this.$refs.fileInput.files && this.$refs.fileInput.files.length > 0) {
					const imgRes = await this.$store.dispatch(`scommunity/${ACT_UPLOAD_SCOMMUNITY_POST_IMG}`, { cmntId: this.scommunityNo, fileList: this.$refs.fileInput.files });
					if (imgRes.data.result.number !== 200) {
						//await this.$store.dispatch(`common/${MUT_SHOW_ALERT}`, { title: '공지이미지는 이미지 파일만 업로드 가능합니다.' });
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '포스트 이미지는 이미지 파일만 업로드 가능합니다.'});
						return;
					}

					if (imgRes && imgRes.data && imgRes.data.result && imgRes.data.result.number === 200) {
						imgPath = imgRes.data.filePath;
						this.previewImage = imgPath;
						this.uploadPreviewImage = imgPath;
					}

					this.$refs.fileInput.value = null;
				}

				this.$store.dispatch(`scommunity/${ACT_UPDATE_SCOMMUNITY_POST}`, {
					cmntId: this.postItem.cmntId,
					cmntPostId: this.postItem.cmntPostId,
					params: {
						cmntPostCn: this.postCn,
						cmntPostAtch: imgPath,
						userId: this.session.userId,
					}
				}).then(res => {
					if(getResult(res).number === 200){
						this.postItem.cmntPostCn = this.postCn;						
						this.closeLayer('post-edit');
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
					}							
				});
			}
		},
		updateSCommunityPostTop(){
			this.$store.dispatch(`scommunity/${ACT_UPDATE_SCOMMUNITY_POST_TOP}`, {
				cmntId: this.postItem.cmntId,
				cmntPostId: this.postItem.cmntPostId,
				params: {
					userId: this.session.userId,
				}
			}).then(res => {
				if(getResult(res).number === 200){
					this.closeLayer('sub_post_menu');		
					this.$emit('getSCommunityPostList', 1);
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
				}							
			});
		},
		deletePost(){
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: `게시글을 삭제하시겠습니까?`,
				showCancelButton: true,
				yesfunc: () => {
					this.$store.dispatch(`scommunity/${ACT_DELETE_SCOMMUNITY_POST}`, {
						cmntId: this.postItem.cmntId,
						cmntPostId: this.postItem.cmntPostId,
						userId: this.session.userId,
					}).then(res => {
						if(getResult(res).number === 200){
							if(!this.cmntAdminYn){
								this.postItem.cmntPostCn = '본문 내용이 삭제되었습니다.';
								this.previewImage = '';
								this.uploadPreviewImage = '';								
							} else {
								this.$emit('getSCommunityPostList', 1);
							}
							this.closeLayer('sub_post_menu');
						} else {
							this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
						}							
					});
				},
				nofunc: () => {
					this.isLoading = false;
				},
			});			
		},
		insertSCommunityPostRply(){
			if(this.postRplyCnIns.length > 0) {
				if(!this.validatePrhbWrds(this.postRplyCnIns)){
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '작성내용에 금칙어가 포함되어 있습니다.'});
					return;
				}

				this.$store.dispatch(`scommunity/${ACT_INSERT_SCOMMUNITY_POST_RPLY}`, {
					cmntId: this.postItem.cmntId,
					cmntPostId: this.postItem.cmntPostId,
					params: {
						cmntPostRplyCn: this.postRplyCnIns,
						cmntPostRplyTyp: 'R',
						userId: this.session.userId,
					}
				}).then(res => {
					if(getResult(res).number === 200){
						this.getSCommunityPostRplyList();
						this.postRplyCnIns = '';
						this.postRplyInsLength = 0;
						this.postItem.rplyCount += 1;
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
					}							
				});
			}
		},
		updateSCommunityPostRply(postRplyId, id){
			if(this.postRplyCn.length > 0) {
				if(!this.validatePrhbWrds(this.postRplyCn)){
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '작성내용에 금칙어가 포함되어 있습니다.'});
					return;
				}

				this.$store.dispatch(`scommunity/${ACT_UPDATE_SCOMMUNITY_POST_RPLY}`, {
					cmntId: this.postItem.cmntId,
					cmntPostId: this.postItem.cmntPostId,
					cmntPostRplyId: postRplyId,
					params: {
						cmntPostRplyCn: this.postRplyCn,
						userId: this.session.userId,
					}
				}).then(res => {
					if(getResult(res).number === 200){
						const cnLayer = document.querySelector('#' + id);
						cnLayer.innerHTML = this.cnConvertHtml(this.postRplyCn);
						this.closeLayer('rply-edit');
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
					}							
				});
			}
		},	
		deletePostRply(postRplyId){
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: `댓글을 삭제하시겠습니까?`,
				showCancelButton: true,
				yesfunc: () => {
					this.$store.dispatch(`scommunity/${ACT_DELETE_SCOMMUNITY_POST_RPLY}`, {
						cmntId: this.postItem.cmntId,
						cmntPostId: this.postItem.cmntPostId,
						cmntPostRplyId: postRplyId,
						userId: this.session.userId,
					}).then(res => {
						if(getResult(res).number === 200){							
							if(this.cmntAdminYn){
								this.postItem.rplyCount -= 1;
							}
							this.closeLayer('sub_post_rply_menu');
							this.getSCommunityPostRplyList();									
						} else {
							this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
						}							
					});
				},
				nofunc: () => {
					this.isLoading = false;
				},
			});
		},	
		insertSCommunityPostRRply(postRplyId){
			if(this.postRplyCn.length > 0) {
				if(!this.validatePrhbWrds(this.postRplyCn)){
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '작성내용에 금칙어가 포함되어 있습니다.'});
					return;
				}

				this.$store.dispatch(`scommunity/${ACT_INSERT_SCOMMUNITY_POST_RRPLY}`, {
					cmntId: this.postItem.cmntId,
					cmntPostId: this.postItem.cmntPostId,
					cmntPostRplyId: postRplyId,
					params: {
						cmntPostRplyCn: this.postRplyCn,
						cmntPostRplyTyp: 'RR',
						userId: this.session.userId,
					}
				}).then(res => {
					if(getResult(res).number === 200){
						this.getSCommunityPostRplyList();
						this.postItem.rplyCount += 1;
						this.closeLayer('post-rrply-input');
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
					}							
				});
			}
		},
		validatePrhbWrds(cn){
			// 금칙어 벨리데이션
            const prhbWrds = this.prhbWrds.map((item, index) => item.prhbWrd);
			let newSrchWrd = new Array();
            for(let i = 0; i < prhbWrds.length; i++){
				const findTrue = cn.includes(prhbWrds[i])
				newSrchWrd.push(findTrue);
			}

            if(newSrchWrd.includes(true)){
                //Swal.fire({text:"검색어에 금칙어가 포함되어 있습니다."});
                return false;
            } else {
				return true;
			}

		},
	},
};
</script>
