<template>
    <div class="flex-1">
        <section class="bbs-detail">
            <!-- s: 게시판 본문 헤더 -->
            <div class="bbs-detail-header">
                <div class="bbs-detail-header__back">
                    <a @click="callParentMethod()">
                        <i class="icon-bbs-back"></i>
                        <span class="text">{{ this.scommunityBrdType == 'NOTICE' ? '공지사항' : '자료실' }}</span>
                    </a>
                </div>
                <h3 class="bbs-detail-header__title">{{ boardInfo.cmntBrdTit }}</h3>
                <!-- 사용자 정보 -->
                <div class="scomm-detail-user">
                    <div class="scomm-detail-user__photo">
                        <img v-if="boardInfo.profileImg" :src="boardInfo.profileImg" alt="사진" />
						<img v-else src="" alt="" />
                    </div>
                    <div class="scomm-detail-user__text">
                        <span class="scomm-detail-user__name">{{ boardInfo.rgtrNm }}</span>
                        <span class="scomm-detail-user__date">{{ regDtTimeFormat(boardInfo.regDt) }}</span>
                        <span class="scomm-detail-user__count">조회 {{ boardInfo.cmntBrdViewCnt }}</span>
                    </div>
                </div>
                <!-- //사용자 정보 -->
            </div>
            <!-- e: 게시판 본문 헤더 -->
            <!-- s: 게시판 본문 바디 -->
            <div class="bbs-detail-body">
                <!-- s: 게시판 본문 콘텐츠 -->
                <div class="bbs-detail-content content" v-html="boardInfo.cmntBrdCn">
                    <!-- <p>게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.</p>
                    <p>첨부파일을 다운받아서 사용하세요.</p>
                    <p>다른 자료는 자료실목록에서 확인 할 수 있습니다.</p>
                    <ul>
                        <li>목록테스트</li>
                        <li>목록테스트</li>
                        <li>목록테스트</li>
                        <li>목록테스트</li>
                        <li>목록테스트</li>
                    </ul>
                    <ol>
                        <li>목록테스트</li>
                        <li>목록테스트</li>
                        <li>목록테스트</li>
                        <li>목록테스트</li>
                        <li>목록테스트</li>
                    </ol> -->
                </div>
                <!-- e: 게시판 본문 콘텐츠 -->
                <!-- s: 게시판 본문 푸터 -->
                <div v-if="this.scommunityBrdType == 'FILEBRD' && this.boardAtch.length > 0" class="bbs-detail-footer">
                    <dl class="attach-list-dl">
                        <dt class="attach-list-dt">첨부파일</dt>
                        <dd class="attach-list-dd">
                            <ul class="attach-list">
                                <li v-for="(item, idx) in this.boardAtch" :key="idx" class="attach-list-item">
                                    <a :href="item.atchFilePtUrl">
                                        <span class="text">{{ item.atchFileNm }}</span>
                                        <i class="icon-attach"></i>
                                    </a>
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </div>
                <!-- e: 게시판 본문 푸터 -->
            </div>
            <!-- e: 게시판 본문 바디 -->
            <div class="bbs-detail-buttons buttons">
                <a class="button-default is-large is-rounded is-primary" @click="callParentMethod()">목록</a>
            </div>
        </section>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ACT_GET_SCOMMUNITY_BRD_INFO, ACT_GET_SCOMMUNITY_BRD_ATCH } from '../../store/_act_consts';
import { timestampToDateFormat, lengthCheck, getItem, getItems } from '../../assets/js/utils';
export default {
	name: 'ScommunityBoardDetail',
	components: {},
	data: () => ({
		// hasMore: false,
		// totalCount: 0,
		// pageSize: 10,
		// pageListSize: 5,
		// pageNo: 1,
        // pageType: 'L', // L : List, D : Detail
        // pagingMethodName: 'getSCommunityBoardList',
        // boardEmpty: true,
        boardInfo: {},
        boardAtch: [],
	}),
	props: {
        scommunityNo: String,
        scommunityBrdMntId: Number,
        scommunityBrdId: Number,
        scommunityBrdType: String,
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
        queries() {
			const result = {};
			return result;
		},
	},
	watch: {},
	created() {
        this.getSCommunityBoardInfo();
        if(this.scommunityBrdType == 'FILEBRD'){
            this.getSCommunityBoardAtch();
        }
    },
    mounted() {},
	unmounted(){},
	methods: {
        regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy.MM.dd hh:mm');
		},
        getSCommunityBoardInfo(){
			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_BRD_INFO}`, {
				cmntId: this.scommunityNo,
				cmntBrdMntId: this.scommunityBrdMntId,
				cmntBrdId: this.scommunityBrdId,
			}).then(res => {
                if(lengthCheck(res)){
					this.boardInfo = getItem(res);
				}
			});
		},
        getSCommunityBoardAtch(){
			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_BRD_ATCH}`, {
				cmntId: this.scommunityNo,
				cmntBrdMntId: this.scommunityBrdMntId,
				cmntBrdId: this.scommunityBrdId,
			}).then(res => {
                if(lengthCheck(res)){
					this.boardAtch = getItems(res);
				}
			});
		},
        callParentMethod() {
			this.$emit('setBoardData', 0, 'L');
		},
	},
};
</script>