<template>
	<div>
		<img @click="lightboxEffect()" :src="thumbnail" class="light-box__thumbnail" />
		<transition name="fade" mode="out-in">
			<div @click.stop="bg = !bg" class="light-box__bg" v-if="bg"></div>
		</transition>

		<div v-if="bg">
			<div v-if="bg" class="light-box__container">
				<div class="light-box__close" @click.stop="bg = !bg"></div>

				<transition name="fade" mode="out-in">
					<img :src="largeImage" class="light-box__container__img" />
				</transition>
			</div>

			<div class="light-box__caption" v-if="caption">
				<p>{{ caption }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			bg: false,
			count: true,
		};
	},
	props: {
		thumbnail: {
			type: String,
			required: true,
		},
		largeImage: {
			type: String,
			required: true,
		},
		caption: {
			type: String,
		},
	},
	methods: {
		lightboxEffect(curr) {
			this.currentImage = curr;
			this.bg = !this.bg;
		},
	},
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.light-box {
	&__bg {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 1000;
		backdrop-filter: blur(10px);
	}
	&__thumbnail {
		cursor: pointer;
	}
	&__close {
		position: absolute;
		width: 15px;
		height: 15px;
		top: -30px;
		right: 0px;
		background-image: url(~@/assets/img/common/icon_popup_close_w.svg);
		background-size: contain;
		background-position: center;
	}
	&__container {
		display: flex;
		position: fixed;
		z-index: 2000;
		max-width: 900px;
		width: calc(100% - 20px);
		top: 50%;
		left: 50%;
		justify-content: center;
		align-items: center;
		transform: translate(-50%, -50%);
		img {
			max-width: initial;
			width: 100%;
			height: auto;
			align-self: center;
			object-fit: cover;
		}
	}
	&__btn {
		background-size: contain;
		background-position: center;
		align-self: center;
		padding: 15px;
	}
	&__close,
	&__btn {
		cursor: pointer;
	}
	&__close,
	&__btn,
	&__caption,
	&__count {
		position: absolute;
		z-index: 3000;
	}
	&__caption {
		bottom: 0;
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		color: #fff;
		font-size: 20px;
		justify-content: center;
	}
	&__count {
		left: 20px;
		font-size: 16px;
		color: #fff;
		top: 14px;
		padding: 0;
		margin: 0;
	}
}
</style>
