<template>
    <div class="flex-1" v-if="this.pageType == 'L'">
        <section v-if="this.scommunityBrdInfo.cmntBrdTyp == 'NOTICE'" class="bbs-list">
            <div class="bbs-list-header">
                <h3 class="bbs-list-title">공지사항</h3>
            </div>
            <div v-if="this.boardEmpty" class="no-results-found">
                <p class="text">등록된 공지사항이 없습니다.</p>
            </div>
            <table v-else class="table table-bbs is-bordered">
                <caption class="sr-only">
                    제목, 작성자, 작성일, 조회수 목록의 게시판
                </caption>
                <colgroup>
                    <col />
                    <col style="width: 100px" />
                    <col style="width: 100px" />
                    <col style="width: 100px" />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">제목</th>
                        <th scope="col">작성자</th>
                        <th scope="col">작성일</th>
                        <th scope="col">조회</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in this.boards" :key="idx">
                        <td class="bbs-title">
                            <a @click="setBoardData(item.cmntBrdId, 'D')">
                                <strong>{{ item.cmntBrdTit }}</strong>
                                <!-- <em>[25]</em> -->
                            </a>
                        </td>
                        <td class="bbs-author">{{ item.rgtrNm }}</td>
                        <td class="bbs-date">{{ regDtTimeFormat(item.regDt) }}</td>
                        <td class="bbs-count">{{ item.cmntBrdViewCnt }}</td>
                    </tr>                    
                    <!-- <div v-if="this.boardEmpty" class="no-results-found">
                        <p class="text">등록된 게시글이 없습니다.</p>
                    </div> -->
                </tbody>
            </table>
            <!-- pagination -->
            <s-comnnunity-pagination page-name="ScommunityNotice" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @getSCommunityBoardList="getSCommunityBoardList"></s-comnnunity-pagination>
            <!-- //pagination -->
        </section>
        <section v-else-if="this.scommunityBrdInfo.cmntBrdTyp == 'FILEBRD'" class="bbs-list">
            <div class="bbs-list-header">
                <h3 class="bbs-list-title">자료실</h3>
            </div>
            <div v-if="this.boardEmpty" class="no-results-found">
                <p class="text">등록된 게시글이 없습니다.</p>
            </div>
            <table v-else class="table table-bbs is-bordered">
                <caption class="sr-only">
                    제목, 다운로드, 작성자, 작성일, 조회수 목록의 게시판
                </caption>
                <colgroup>
                    <col />
                    <col style="width: 70px" />
                    <col style="width: 100px" />
                    <col style="width: 100px" />
                    <col style="width: 100px" />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">제목</th>
                        <th scope="col">다운로드</th>
                        <th scope="col">작성자</th>
                        <th scope="col">작성일</th>
                        <th scope="col">조회</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in this.boards" :key="idx">
                        <td class="bbs-title">
                            <a @click="setBoardData(item.cmntBrdId, 'D')">
                                <strong>{{ item.cmntBrdTit }}</strong>
                                <!-- <em>[25]</em> -->
                            </a>
                        </td>
                        <td class="bbs-download">
                            <a @click="setBoardData(item.cmntBrdId, 'D')"><i class="icon-download"></i></a>
                        </td>
                        <td class="bbs-author">{{ item.rgtrNm }}</td>
                        <td class="bbs-date">{{ regDtTimeFormat(item.regDt) }}</td>
                        <td class="bbs-count">{{ item.cmntBrdViewCnt }}</td>
                    </tr>
                </tbody>
            </table>
            <!-- pagination -->
            <s-comnnunity-pagination page-name="ScommunityBoard" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @getSCommunityBoardList="getSCommunityBoardList" />
            <!-- //pagination -->
        </section>
    </div>
    <s-comnnunity-board-detail v-else-if="this.pageType == 'D'" 
        :scommunityNo="scommunityNo" 
        :scommunityBrdMntId="scommunityBrdMntId" 
        :scommunityBrdId="scommunityBrdId" 
        :scommunityBrdType="this.scommunityBrdInfo.cmntBrdTyp" 
        @setBoardData="setBoardData" />
</template>
<script>
import { mapGetters } from 'vuex';
import { ACT_GET_SCOMMUNITY_BRD_LIST } from '../../store/_act_consts';
import { timestampToDateFormat, getItems, lengthCheck, setPaging } from '../../assets/js/utils';
import SComnnunityPagination from '@/components/scommunity/SCommunityPagination.vue';
import SComnnunityBoardDetail from '@/components/scommunity/SCommunityBoardDetail.vue';
export default {
	name: 'ScommunityBoard',
	components: {
        SComnnunityPagination,
        SComnnunityBoardDetail,
	},
	data: () => ({
		hasMore: false,
		totalCount: 0,
		pageSize: 10,
		pageListSize: 5,
		pageNo: 1,
        pageType: 'L', // L : List, D : Detail
        pagingMethodName: 'getSCommunityBoardList',
        boardEmpty: true,
        boards: [],
        scommunityBrdId: 0,
	}),
	props: {
        scommunityNo: String,
        scommunityBrdMntId: Number,
        scommunityBrdInfo: {
			type: Object,
			default: {},
		},
        initBrdNoticeNo: Number,
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
        queries() {
			const result = {};
			return result;
		},
	},
	watch: {
        scommunityBrdInfo: function(brdInfo){
			this.getSCommunityBoardList(1);
            this.pageType = 'L';
		},
    },
	created() {
        if(this.initBrdNoticeNo != 0 && this.pageType == 'L') {
            this.setBoardData(this.initBrdNoticeNo, 'D');
        } 
        
        this.getSCommunityBoardList(1);
    },
    mounted() {},
	unmounted(){},
	methods: {
        regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy.MM.dd');
		},
        getSCommunityBoardList(no){
            this.boards = null;
			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_BRD_LIST}`, {
				cmntId: this.scommunityNo,
				cmntBrdMntId: this.scommunityBrdMntId,
				params: {
					pageNo: no,
					pageSize: this.pageSize,
				}
			}).then(res => {
				if(no === 1){
					if(lengthCheck(res)){
						this.boards = getItems(res);
                        this.boardEmpty = false;
					} else {
                        this.boards = null;
						this.boardEmpty = true;
					}					
				} else {
					if(lengthCheck(res)){
						this.boards = getItems(res);
                        this.boardEmpty = false;
					}				
				}				
				setPaging(this, res);
			});
		},
        setBoardData(brdId, pageType){
            this.scommunityBrdId = brdId;
            this.pageType = pageType;
        },
	},
};
</script>